import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';
import { Link, useParams } from 'react-router-dom'
import { useSelector } from "react-redux";
import Wrapper from '../../routes/Wrapper'

import { formatChatTimestamp, formatDate } from "../../utils/common";
import {
    useGetProfileQuery,
} from "../../store/services/userService";
import {
    useGetChatIdQuery,
    useGetMessagesListMutation,
    useSendNewMessageMutation
} from "../../store/services/chatService"

import {
    ArrowLeftIcon,
    SendIcon,
    ImageIcon,
    VideoIcon,
    FileIcon,
    ProfileIcon,
    ClockIcon,
    LocationIcon,
    NotificationIcon,
} from '../../assets/Icons'

const Chat = () => {
    const { userToken } = useSelector((state) => state.authReducer);
    const ENDPOINT = process.env.REACT_APP_API_URL;
    const { sessionId } = useParams();
    const [chatId, setChatId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [participants, setparticipants] = useState([]);
    const [sessionDetails, setSessionDetails] = useState({
        description: "",
        location: "",
        title: "",
        subject: "",
        participantCount: 0,
        participantLimit: 0,
        date: "",
        time: ""
    });
    const [formData, setFormData] = useState(
        { content: "" }
    )
    const socketRef = useRef(null);
    console.log("messages",messages)


    useEffect(() => {
        // Initialize the socket inside useEffect
        socketRef.current = socketIOClient(ENDPOINT);

        const fetchChatIdAndMessages = async () => {
            try {
                // Fetch the chat ID first
                const chatResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/session/${sessionId}/chat`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });
                const chatData = await chatResponse.json();
                console.log("chatData",chatData)
                setparticipants(chatData?.data?.participants)
                if (chatResponse.ok) {
                    setChatId(chatData.chatId);

                    // Fetch the messages for the chat
                    const messagesResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatData.chatId}/messages`, {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    });
                    const messagesData = await messagesResponse.json();
                    console.log("messagesData",messagesData)
                    if (messagesResponse.ok) {
                        setMessages(messagesData);
                    } else {
                        console.error('Error fetching messages:', messagesData);
                    }
                } else {
                    console.error('Error fetching chat ID:', chatData);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchChatIdAndMessages();

        // Join the chat room for the specific session
        socketRef.current.emit('joinRoom', { sessionId });

        // Listen for incoming messages
        socketRef.current.on('message', (message) => {
            console.log("message",message)
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        // Cleanup on component unmount
        return () => {
            socketRef.current.disconnect();
        };
    }, [sessionId]);

    useEffect(() => {
        const fetchSessionDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/study-sessions//session/${sessionId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                        'Content-Type': 'application/json'
                    },
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch session details');
                }
    
                const sessionDetails = await response.json();
                console.log('Session Details:', sessionDetails);
    
                // You can set these details to a state if you want to use them in your component
                // setState(sessionDetails);
                setSessionDetails({
                    description: sessionDetails.description,
                    location: sessionDetails.location,
                    title: sessionDetails.title,
                    date: sessionDetails.date,
                    time: sessionDetails.time,
                    participantCount: sessionDetails.participantCount,
                    participantLimit: sessionDetails.participantLimit,
                    subject: sessionDetails.subject

                    // Include any other details you need
                });
            } catch (error) {
                console.error('Error fetching session details:', error);
            }
        };
    
        fetchSessionDetails();
    
        // Other useEffect content...
    }, [sessionId, userToken]); // Add userToken dependency if your lint rules require it
    
    


    const {
        data: userProfileData,
        isLoading: userProfileDataLoading,
        isFetching: userProfileDataFetching,
    } = useGetProfileQuery();
    const {
        data: chatIdData,
        isLoading: chatIdDataLoading,
        isFetching: chatIdDataFetching,
    } = useGetChatIdQuery(sessionId);


    useEffect(() => {
        if (chatIdData) {
            socketRef.current = socketIOClient(ENDPOINT);
            setChatId(chatIdData?.chatId)
        }
    }, [chatIdData])


    const [messagesChatData, messagesResp] = useGetMessagesListMutation();
    const messagesFunction = () => {
        messagesChatData(chatId);
    };
    useEffect(() => {
        messagesFunction()
    }, [chatId])
    useEffect(() => {
        if (messagesResp.isSuccess) {
            setMessages(messagesResp?.data || [])
        }
    }, [messagesResp])


    const [newMessageData, newMessageResp] = useSendNewMessageMutation();

    const newMessageFunction = () => {
        if (chatId) {
            newMessageData({ formData, chatId });
        }
    };

    useEffect(() => {
        if (newMessageResp.isSuccess) {
            setFormData({ content: "" })
            // Emit the message via Socket.IO for real-time communication
            socketRef.current.emit('sendMessage', {
                sessionId,
                content: formData.content,
                timestamp: new Date(),
                sender : userProfileData?._id
            });
        }
    }, [newMessageResp])
    // useEffect(() => {
    //     // Initialize the socket inside useEffect
    //     socketRef.current = socketIOClient(ENDPOINT);

    //     // Join the chat room for the specific session
    //     socketRef.current.emit('joinRoom', { sessionId });

    //     // Listen for incoming messages
    //     socketRef.current.on('message', (message) => {
    //         setMessages((prevMessages) => [...prevMessages, message]);
    //     });

    //     // Cleanup on component unmount
    //     return () => {
    //         socketRef.current.disconnect();
    //     };
    // }, [sessionId]);

    const notificationList = [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
    ]

    return (
        <Wrapper>
            <div className='chat-page flex bg-white min-h-[730px] h-full w-full'>
                <div className='wrapWidth flex gap-5'>
                    {/* Chatting Side */}
                    <div className='flex flex-col flex-1 h-full'>
                        <div className='flex flex-col w-full bd-right h-full'>
                            {/* Chat Header */}
                            <div className='flex sm:flex-row flex-col-reverse items-center sm:justify-between justify-start gap-4 py-3'>
                                <div className='flex flex-col'>
                                    <h1 className='text-[#6C6C6C] text-sm font-normal'>
                                        Live Chat Session
                                    </h1>
                                    <h1 className='text-[#1E1E1E] text-lg font-semibold font-dup-sans '>
                                        {sessionDetails.title}
                                    </h1>
                                </div>
                                <div className='flex items-center sm:justify-end justify-start w-full sm:w-max'>
                                    <Link to="/" className='btn flex items-center justify-center gap-2 hover:shadow anim'>
                                        <div className='flex items-center justify-center h-3 w-5'>
                                            <ArrowLeftIcon />
                                        </div>
                                        <h1 className='text-[#1E1E1E] font-medium text-base'>Dashboard</h1>
                                    </Link>
                                </div>
                            </div>
                            {/* Chatting Area */}
                            <div className='bd-top-bottom flex items-end h-full p-1 gap-6'>
                                <div className='flex flex-col w-full h-[35rem] overflow-hidden overflow-y-auto gap-6'>
                                    {messages?.length > 0 && userProfileData ?
                                        <>
                                            {messages.map((item, index) => (

                                                <>
                                                    {userProfileData?._id == item?.sender ?
                                                        /*   Sender Text */
                                                        <div key={index} className='senderDiv flex items-center justify-end'>
                                                            <div className='flex flex-col gap-2'>
                                                                <div className="flex items-center gap-2">
                                                                    <div 
                                                                       className="h-8 w-8 rounded-full bg-gray-300 text-gray-600 font-semibold flex items-center justify-center">
                                                                           {(participants?.find((item2) => item2?._id === item?.sender))?.name.substring(0, 1).toUpperCase()}
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h1 className="text-lg font-medium font-dup-sans leading-5 text-[#1E1E1E]">
                                                                            You
                                                                        </h1>
                                                                        <label className="text-[#6C6C6C] text-[10px] font-medium">
                                                                            {formatChatTimestamp(item.timestamp)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='flex items-center send-text py-2 px-4 bg-themeColor text-white text-sm font-normal'>
                                                                    {item.content}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        /* Receiver Text */
                                                        <div key={index} className='receiverDiv flex items-center justify-start' >
                                                            <div className='flex flex-col gap-2'>
                                                                <div className="flex items-center gap-2">
                                                                <div 
                                                                       
                                                                        className="h-8 w-8 rounded-full bg-gray-300 text-gray-600 font-semibold flex items-center justify-center">
                                                                            {(participants?.find((item2) => item2?._id === item?.sender))?.name.substring(0, 1).toUpperCase()}
                                                                        </div>
                                                                    <div className="flex flex-col">
                                                                    
                                                                        <h1 className="text-lg font-medium font-dup-sans leading-5 text-[#1E1E1E]">
                                                                           {(participants?.find((item2) => item2?._id === item?.sender))?.name} 
                                                                        </h1>
                                                                        <label className="text-[#6C6C6C] text-[10px] font-medium">
                                                                            {formatChatTimestamp(item.timestamp)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='flex items-center send-text py-2 px-4 text-[#585858] text-sm font-normal'>
                                                                    {item.content}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            ))
                                            }
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                            {/* Chatting bottom area */}
                            <div className='flex flex-col'>
                                <div className='flex items-center border bd-full p-3 sm:mt-8 ms:mx-3 my-3 mx-2 rounded-lg focus-within:border-themeColor anim'>
                                    <input
                                        type='text'
                                        required
                                        value={formData.content}
                                        onChange={(e) => {
                                            setFormData({ ...formData, content: e.target.value })
                                        }
                                        }
                                        placeholder='Aa'
                                        className='w-full text-[#828084] font-medium text-base'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault(); // Prevents the default action of the enter key in a form
                                                newMessageFunction(); // Call the function to send the message
                                            }
                                        }}
                                    />
                                    <button type='submit' className='flex items-center justify-center h-8 w-12 bg-themeColor rounded-xl hover:cursor-pointer'
                                        onClick={newMessageFunction}
                                    >
                                        <div className='flex items-center justify-center h-4 w-4'>
                                            <SendIcon />
                                        </div>
                                    </button>
                                </div>
                             {/*   <div className='flex items-center gap-2 sm:mb-4 mb-2 px-3'>
                                    <div className='flex items-center p-2 cursor-pointer gap-2'>
                                        <div className='flex items-center justify-center h-3 w-3'>
                                            <ImageIcon />
                                        </div>
                                        <h1 className='text-[#6C6C6C] text-sm font-medium'>Image</h1>
                                    </div>
                                    <div className='flex items-center p-2 cursor-pointer gap-2'>
                                        <div className='flex items-center justify-center h-4 w-4'>
                                            <VideoIcon />
                                        </div>
                                        <h1 className='text-[#6C6C6C] text-sm font-medium'>Video</h1>
                                    </div>
                                    <div className='flex items-center p-2 cursor-pointer gap-2'>
                                        <div className='flex items-center justify-center h-4 w-4'>
                                            <FileIcon />
                                        </div>
                                        <h1 className='text-[#6C6C6C] text-sm font-medium'>File</h1>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* Detail Side */}
                    <div className='md:flex hidden flex-col flex-[0.5] p-6 gap-5'>
                        <div className='flex flex-col w-full gap-3'>
                            <h1 className='flex flex-1 text-[#1E1E1E] font-semibold text-[28px] leading-8 font-dup-sans'>{sessionDetails.title}</h1>
                            <div className='flex flex-1 items-center gap-2 flex-wrap'>
                                <button className={`btn py-1 px-3 uppercase text-sm text-[#1E1E1E] font-normal bg-[#FEF182]`}>
                                    {sessionDetails.subject}
                                </button>
                                <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase bg-[#00633E] text-sm font-normal'>
                                    <div className='flex items-center justify-center h-4 w-4'>
                                        <ProfileIcon />
                                    </div>
                                    <h6 className='text-[#FFF] text-sm font-normal'>{`${sessionDetails.participantCount}/${sessionDetails.participantLimit}`}</h6>
                                </button>
                                <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase border border-[#D7D7D7] border-solid text-sm font-normal'>
                                    <div className='flex items-center justify-center h-4 w-4'>
                                        <ClockIcon />
                                    </div>
                                    <h6 className='text-[#6C6C6C] text-sm font-normal'>{formatDate(sessionDetails.date)} {" "} {sessionDetails.time}</h6>
                                </button>
                                <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase border border-[#D7D7D7] border-solid text-sm font-normal'>
                                    <div className='flex items-center justify-center h-4 w-4'>
                                        <LocationIcon />
                                    </div>
                                    <h6 className='text-[#6C6C6C] text-sm font-normal uppercase'>{sessionDetails.location}</h6>
                                </button>
                                <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase border border-[#D7D7D7] border-solid text-sm font-normal'>
                                    <h6 className='text-[#6C6C6C] text-sm font-normal uppercase'>{sessionDetails.description}</h6>
                                </button>
                            </div>
                        </div>
                        

                        {/* Recent Updates 
                        <div className='flex flex-col bg-[#F6F8FC] p-4 gap-3'>
                            <h1 className='text-[#1E1E1E] text-lg font-semibold mb-4'>Recent Updates</h1>
                            <div className='flex flex-col w-full gap-2 max-h-48 overflow-hidden overflow-y-auto'>
                                {notificationList.map((item, index) => (
                                    <div key={index} className='flex items-center gap-3 bg-white rounded-lg p-4'>
                                        <div className='flex items-center justify-center h-6 w-6'>
                                            <NotificationIcon />
                                        </div>
                                        <h1 className='text-[#6C6C6C] text-xs font-normal'>Session time changed from 3:00PM to 4:00PM</h1>
                                    </div>
                                ))}
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Chat