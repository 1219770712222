import React from "react";

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 17"
    >
      <g clipPath="url(#clip0_24_3817)">
        <path
          fill="#fff"
          d="M11.313.5H4.686A4.688 4.688 0 000 5.188v6.625A4.688 4.688 0 004.688 16.5h6.625A4.688 4.688 0 0016 11.812V5.189A4.688 4.688 0 0011.312.5zm1.218 8c0 .432-.35.781-.781.781H8.781v2.969a.781.781 0 11-1.562 0V9.281H4.25a.781.781 0 110-1.562h2.969V4.75a.781.781 0 111.562 0v2.969h2.969c.432 0 .781.35.781.781z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_24_3817">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddIcon;
