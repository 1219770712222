import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userService = createApi({
    reducerPath: "userService",
    tagTypes: "user",

    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,

        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const authorization = reducers.authReducer?.userToken;
            headers.set(
                "Authorization",
                `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
            );
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => {
        return {

            //   Get the profile Data
            getProfile: builder.query({
                query: () => {
                    return {
                        url: "/api/users/profile",
                        method: "GET",
                    };
                },
                providesTags: ["user"],
            }),
            userUpdateSetting: builder.mutation({
                query: (data) => {
                  return {
                    url: "api/users/updateusersettings",
                    method: "POST",
                    body: data,
                  };
                },
              })
        };
    },
});

export const {
    useGetProfileQuery,
    useUserUpdateSettingMutation
} = userService;

export default userService;