import React from "react";

function AvatarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="185"
      fill="none"
      viewBox="0 0 144 211"
    >
      <path
        fill="#1E1E1E"
        d="M21.963 93.227s2.75 3.09 6.485 2.679l-1.033-7.376-5.452 4.697z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M21.814 93.359a.199.199 0 01.019-.283l5.452-4.696a.199.199 0 01.326.123l1.033 7.375a.2.2 0 01-.175.225c-2.56.282-5.191-1.097-6.655-2.744zm.437-.119c.586.585 2.948 2.716 5.97 2.487l-.953-6.808-5.017 4.321z"
      ></path>
      <path
        fill="#5551FF"
        d="M62.89 179.686l-6.505 1.188a4.48 4.48 0 01-3.896-1.167L34.002 162.05a4.477 4.477 0 01-1.344-3.838l.888-6.552 29.343 28.026z"
      ></path>
      <path
        fill="#5551FF"
        d="M52.352 179.851l-18.488-17.657a4.691 4.691 0 01-1.403-4.009l.888-6.552a.199.199 0 01.335-.117l29.343 28.026a.2.2 0 01-.102.34l-6.504 1.187a4.686 4.686 0 01-4.069-1.218zm-18.661-27.778l-.836 6.165a4.29 4.29 0 001.284 3.668l18.488 17.658a4.291 4.291 0 003.723 1.114l6.12-1.117-28.78-27.488z"
      ></path>
      <path
        fill="#D9E5E9"
        d="M98.21 159.22l5.642 15.897a20.493 20.493 0 01-2.58 18.686l-11.12 15.728a3.474 3.474 0 01-6.129-3.113l4.18-12.423a2.411 2.411 0 00-.21-1.998l-8.078-13.622L98.21 159.22zM52.03 115.113l-16.14-4.906a20.497 20.497 0 00-18.548 3.435l-15.2 11.83a3.474 3.474 0 003.39 5.98l12.219-4.746a2.408 2.408 0 012.005.119l13.979 7.443 18.295-19.155z"
      ></path>
      <path
        fill="#00633E"
        d="M140.317 107.329a50.254 50.254 0 002.64-17.178l-.455-21.423a3.016 3.016 0 00-3.09-2.951l-21.421.53a50.257 50.257 0 00-17.039 3.425l39.365 37.597z"
      ></path>
      <path
        fill="#00633E"
        d="M140.179 107.473l-39.365-37.597a.2.2 0 01.066-.329 50.317 50.317 0 0117.106-3.44l21.421-.529a3.212 3.212 0 013.293 3.146l.455 21.422a50.36 50.36 0 01-2.65 17.247.199.199 0 01-.326.08zm-38.863-37.668l38.911 37.164a49.932 49.932 0 002.531-16.814l-.455-21.423a2.814 2.814 0 00-2.886-2.757l-21.421.53a49.908 49.908 0 00-16.68 3.3z"
      ></path>
      <path
        fill="#fff"
        d="M129.049 125.95l-55.205 58.782a3.919 3.919 0 01-5.54.127L27.79 146.164a3.918 3.918 0 01-.127-5.539l55.205-58.782a50.214 50.214 0 0118.087-12.107l39.358 37.59a50.22 50.22 0 01-11.264 18.624z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M68.167 185.003l-40.514-38.696a4.122 4.122 0 01-.133-5.82l55.203-58.78a50.762 50.762 0 0118.16-12.156.198.198 0 01.209.041l39.358 37.591a.2.2 0 01.051.207 50.763 50.763 0 01-11.308 18.696l-55.205 58.782a4.118 4.118 0 01-5.821.135zm32.742-115.036A50.368 50.368 0 0083.012 81.98l-55.204 58.781a3.724 3.724 0 00.12 5.259l40.514 38.695c1.526 1.458 3.874 1.328 5.258-.121l55.204-58.78.001-.001a50.353 50.353 0 0011.178-18.43l-39.174-37.416z"
      ></path>
      <path
        fill="#fff"
        d="M105.196 76.568c1.577-.064 4.888-.32 4.869-1.347-.022-1.229-6.981-.848-6.981-.848s3.236-4.452 1.481-5.039c-1.784-.597-2.469 3.143-5.758 3.22-1.92.044-2.593.047-2.824.042l-1.445 8.615c.675.113 2.658.467 3.186.632 1.372.43 3.571.689 5.778.658 9.395-.129 1.357-2.005 1.357-2.005 6.278.552 5.783-1.306 1.09-1.807 6.837.426 5.701-2.193-.753-2.121z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M97.665 82.032c-.495-.155-2.377-.494-3.16-.624a.199.199 0 01-.162-.23l1.445-8.615a.198.198 0 01.2-.166c.229.005.905.002 2.816-.042 1.691-.04 2.648-1.084 3.491-2.006.747-.815 1.393-1.518 2.334-1.204 1.631.546-.174 3.587-1.148 5.01 1.337-.057 6.76-.263 6.784 1.062.011.608-.778 1.044-2.351 1.303 1.475.2 2.509.588 2.792 1.092a.526.526 0 01-.009.555c-.263.417-1.129.672-2.466.746.89.261 1.532.622 1.488 1.079-.067.684-1.616.822-3.057.8.886.301 1.639.677 1.596 1.074-.043.4-.649.778-4.753.834-1.773.025-4.183-.148-5.84-.668zm-2.898-.983c.73.125 2.5.443 3.017.604 1.373.43 3.563.68 5.716.65 3.927-.054 4.325-.418 4.365-.491-.073-.225-1.498-.76-3.05-1.123-.243-.056-.177-.415.062-.391 3.597.317 4.432-.2 4.446-.345.02-.205-.929-.803-3.395-1.067-.257-.027-.221-.41.033-.396 3.091.193 4.217-.245 4.399-.535.026-.041.036-.082-.001-.149-.237-.421-1.831-1.075-5.16-1.04h-.002c-.258 0-.265-.386-.008-.397 2.853-.115 4.689-.564 4.679-1.144-.033-.878-5.866-.703-6.772-.654-.174.011-.267-.184-.172-.315.771-1.062 2.814-4.32 1.579-4.733-.664-.223-1.137.247-1.914 1.095-.853.931-1.915 2.091-3.776 2.134-1.577.037-2.324.044-2.66.044l-1.386 8.253z"
      ></path>
      <path
        fill="#5551FF"
        d="M115.135 108.479c.375 8.213-5.976 15.171-14.189 15.546-8.206.375-15.166-5.976-15.54-14.189-.376-8.212 5.976-15.171 14.183-15.546 8.212-.375 15.171 5.977 15.546 14.189z"
      ></path>
      <path
        fill="#5551FF"
        d="M85.206 109.846c-.379-8.308 6.069-15.375 14.373-15.754 8.325-.381 15.374 6.047 15.754 14.378.379 8.307-6.071 15.375-14.378 15.754-8.323.38-15.369-6.05-15.749-14.378zm14.39-15.357c-8.084.369-14.362 7.25-13.993 15.338.37 8.104 7.224 14.37 15.334 14 8.088-.369 14.368-7.25 13.999-15.339-.37-8.106-7.226-14.37-15.34-14z"
      ></path>
      <path
        fill="#00633E"
        d="M40.5 189.052c-4.985 5.218-17.427 7.447-21.274 8.025a.912.912 0 01-1.045-.997c.4-3.87 2.052-16.406 7.036-21.624 5.927-6.205 12.447-6.184 16.67-2.151 4.218 4.029 4.54 10.542-1.388 16.747z"
      ></path>
      <path
        fill="#fff"
        d="M37.71 186.241c-3.116 3.263-10.898 4.657-13.303 5.019a.571.571 0 01-.654-.624c.25-2.421 1.284-10.26 4.4-13.523 3.707-3.881 7.785-3.868 10.426-1.346 2.638 2.52 2.838 6.593-.868 10.474z"
      ></path>
      <path
        fill="#D9E5E9"
        d="M36.422 176.624l13.544-26.362a.762.762 0 01.601-.41l12.889-1.313a.763.763 0 01.839.802l-.72 12.935a.761.761 0 01-.382.619l-25.712 14.74c-.66.378-1.406-.335-1.059-1.011z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M36.245 176.533l13.544-26.362a.958.958 0 01.758-.517l12.888-1.313a.962.962 0 011.058 1.011l-.72 12.935a.957.957 0 01-.482.781L37.58 177.807c-.83.477-1.772-.421-1.334-1.274zm27.23-27.796l-12.888 1.312a.563.563 0 00-.445.304L36.6 176.715c-.258.501.295 1.027.782.747l25.713-14.739a.562.562 0 00.283-.458l.72-12.935a.564.564 0 00-.622-.593z"
      ></path>
      <path
        fill="#3A9BF3"
        d="M97.337 151.847l1.859 11.435c.06.371.41.623.781.563l34.062-5.537a.681.681 0 00.563-.782l-.404-2.485c-.242-1.487-1.482-2.584-2.983-2.72-4.211-.367-7.934-1.696-11.077-4.18l-22.801 3.706z"
      ></path>
      <path
        fill="#fff"
        d="M60.977 82.102s27.87 6.105 36.772 16.745c14.547 17.39 22.386 49.298 22.386 49.298l-22.794 3.705s-9.524-37.605-34.892-37.056c-28.066.608-34.547-13.348-35.489-27.887 12.149-5.024 23.805-7.968 34.017-4.805z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M97.34 152.049c-.09 0-.17-.06-.192-.15-.024-.093-2.44-9.487-7.916-18.688-5.038-8.466-13.778-18.506-26.779-18.218-12.813.277-22.244-2.522-28.06-8.321-4.57-4.555-7.066-11.016-7.632-19.752a.199.199 0 01.123-.196c14.71-6.084 25.24-7.569 34.144-4.814.612.136 28.085 6.305 36.873 16.81 14.41 17.226 22.348 49.058 22.426 49.378a.199.199 0 01-.161.244l-22.794 3.705-.032.002zM62.99 114.59c12.899 0 21.567 9.983 26.59 18.425 5.088 8.554 7.547 17.271 7.907 18.611l22.403-3.642c-.714-2.792-8.555-32.587-22.293-49.01-8.757-10.466-36.384-16.617-36.662-16.678a.16.16 0 01-.016-.004c-8.783-2.72-19.196-1.258-33.75 4.745.816 12.185 5.413 28.207 35.276 27.559.182-.004.364-.006.545-.006z"
      ></path>
      <path
        fill="#5551FF"
        d="M76.093 82.452a79.47 79.47 0 01-.166 3.857c-.057-.01-5.657-1.712-20-3.293-14.35-1.577-33.961 10.21-33.961 10.21 4.15-10.199 5.296-26.673 7.697-43.446 2.109-14.71 13.265-18.279 20.088-18.5 17.1-.553 23.248 16.878 25.351 32.268.244 1.777.432 3.53.581 5.224.476 5.55.487 10.476.41 13.68z"
      ></path>
      <path
        fill="#5551FF"
        d="M21.782 93.151c4.131-10.151 5.307-26.793 7.685-43.4 2.406-16.79 16.13-18.535 20.277-18.67 17.41-.563 23.49 17.33 25.555 32.44.225 1.643.42 3.404.582 5.233.57 6.658.524 13.917.243 17.57a.198.198 0 01-.255.175c-.025-.007-2.528-.755-8.254-1.704-3.51-.58-7.45-1.112-11.71-1.581-14.12-1.553-33.641 10.065-33.837 10.183a.199.199 0 01-.286-.246zM49.758 31.48c-4.07.132-17.535 1.844-19.898 18.33-2.344 16.364-3.513 32.7-7.497 42.957 3.159-1.808 20.609-11.373 33.586-9.947 5.39.594 14.596 1.807 19.796 3.232.264-4.023.296-10.773-.26-17.262a110.1 110.1 0 00-.58-5.214C72.674 47.245 66.16 30.95 49.758 31.48z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M75.683 68.772c-7.313-.143-13.816-4.03-17.548-9.806l.27-1.727c.698 2.612 3.061 4.516 5.623 5.396 3.153 1.078 6.758.89 11.074.913.243 1.776.431 3.53.58 5.224z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M75.9 68.975l-.221-.005c-7.288-.142-13.884-3.973-17.711-9.896l-.041-.064.428-2.73.243.908c.62 2.324 2.674 4.29 5.494 5.259 3.011 1.03 6.271.884 11.183.903.079.636.333 2.316.605 5.404l.02.22zM58.343 58.922c3.682 5.654 10.01 9.434 17.122 9.646-.151-1.681-.332-3.301-.537-4.822-4.42-.017-7.86.139-10.964-.923-2.629-.903-4.623-2.647-5.487-4.754l-.134.852zM76.092 82.451a79.461 79.461 0 01-.166 3.858s-2.468-.748-8.279-1.71c-4.316-2.541-8.022-7.183-9.512-12.645l.78-1.56c-.127 2.7 1.555 5.229 3.73 6.833 2.18 1.61 4.809 2.457 7.382 3.282 2.02.648 4.045 1.295 6.065 1.942z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M76.105 86.57l-.237-.071c-.024-.008-2.527-.755-8.253-1.704l-.069-.025a18.665 18.665 0 01-1.739-1.169c-6.427-4.849-7.783-11.45-7.884-11.668l1.235-2.47-.045.94c-.111 2.368 1.287 4.922 3.65 6.665 2.165 1.599 4.788 2.439 7.325 3.252l6.206 1.988c-.002.056-.05 2.464-.17 4.016l-.019.246zm-8.388-2.161c4.74.786 7.279 1.437 8.028 1.642.032-.493.103-1.713.145-3.456l-5.923-1.897c-2.568-.822-5.223-1.673-7.44-3.31-2.235-1.65-3.61-3.912-3.796-6.183l-.385.77c1.494 5.398 5.137 9.928 9.37 12.434z"
      ></path>
      <path
        fill="#fff"
        d="M45.626 31.553l-.275-10.866c-5.072-5.859 2.712-11.103 5.872-12.184 4.611-1.394 10.21 1.361 8.793 6.265-.203.7 4.237 7.067-.53 5.778 0 0-.515 2.743-4.925 3.524l3.039 8.407"
      ></path>
      <path
        fill="#1E1E1E"
        d="M57.413 32.544l-3.039-8.406a.199.199 0 01.152-.264c4.207-.744 4.743-3.259 4.764-3.365a.2.2 0 01.248-.155c.923.25 1.525.218 1.788-.095.569-.675-.34-2.632-.941-3.927-.44-.946-.631-1.375-.56-1.62.639-2.21-.243-3.678-1.095-4.519-1.713-1.692-4.777-2.308-7.45-1.5-1.864.638-6.282 3.217-7.226 6.714-.476 1.759.012 3.491 1.447 5.15a.2.2 0 01.049.125l.275 10.866c.002.11-.084.2-.194.204a.205.205 0 01-.203-.194l-.274-10.796c-1.49-1.748-1.99-3.584-1.484-5.459.995-3.685 5.474-6.3 7.488-6.988 2.813-.85 6.04-.195 7.852 1.595 1.312 1.297 1.737 3.041 1.196 4.912-.017.144.291.809.54 1.343.681 1.47 1.616 3.482.884 4.35-.355.421-1.011.51-2.004.272-.21.661-1.15 2.712-4.799 3.435l2.96 8.187a.199.199 0 01-.374.135z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M47.184 17.403s3.653-.532 3.982-8.774c0 0 6.86-1.477 8.727 3.121 0 0 5.14-4.53.863-7.049-2.46-1.447-4.147 1.744-4.147 1.744s.271-7.308-6.387-6.36c-6.658.947-1.894 6.874-5.007 8.254 0 0-4.52-1.84-4.872 3.762-.351 5.603 6.841 5.302 6.841 5.302zM56.55 15.506a.828.828 0 10.001-1.656.828.828 0 000 1.656z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M54.88 12.789a.199.199 0 01-.018-.28c.46-.524.991-.8 1.575-.82 1.043-.037 1.884.775 1.92.81a.2.2 0 01-.28.283c-.007-.008-.748-.728-1.628-.696-.47.017-.903.247-1.289.685a.199.199 0 01-.28.018z"
      ></path>
      <path
        fill="#fff"
        d="M42.195 18.629a2.643 2.643 0 002.642 2.642 2.645 2.645 0 002.65-2.642 2.645 2.645 0 00-2.65-2.642 2.643 2.643 0 00-2.642 2.642z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M44.838 21.47a2.844 2.844 0 01-2.84-2.841 2.844 2.844 0 015.688 0 2.848 2.848 0 01-2.848 2.84zm0-5.284a2.446 2.446 0 00-2.444 2.443 2.446 2.446 0 004.895 0 2.45 2.45 0 00-2.451-2.443zM54.561 24.07l-4.194-.471 5.206 3.865-1.012-3.393z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M55.455 27.623l-5.207-3.865c-.162-.12-.06-.38.141-.357l4.194.472c.08.009.145.064.168.14l1.012 3.394c.056.183-.155.33-.308.216zm-4.378-3.745l4.135 3.07-.804-2.695-3.33-.375zM55.967 20.838a.199.199 0 01.313-.247c.034.043.874 1.056 2.757.693.11-.022.212.05.233.157a.2.2 0 01-.158.233c-1.478.286-2.642-.208-3.145-.836z"
      ></path>
      <path
        fill="#fff"
        d="M38.42 72.684c12.177.146 30.222-21.72 20.947-31.348-9.274-9.63-19.949-3.435-19.949-3.435l.538-1.976s12.272-7.732 23.01 3.417c10.737 11.148-12.31 35.461-26.408 35.293l1.862-1.951z"
      ></path>
      <path
        fill="#fff"
        d="M36.555 74.833a.199.199 0 01-.141-.335l1.862-1.951c.072-.076.161-.061.268-.061 7.99 0 17.975-9.323 21.608-17.727 2.371-5.484 2.041-10.202-.928-13.285-9.054-9.401-19.602-3.462-19.707-3.4a.2.2 0 01-.291-.225l.538-1.976a.2.2 0 01.085-.116c.124-.078 12.535-7.689 23.26 3.447 3.299 3.426 3.722 8.294 1.221 14.077-4.993 11.549-18.98 21.655-27.775 21.552zm1.949-1.95l-1.479 1.55c9.123-.244 22.322-10.628 26.94-21.31 1.696-3.922 2.874-9.472-1.143-13.643-10.107-10.492-21.721-4.001-22.696-3.426l-.398 1.463c2.065-1.005 11.53-4.886 19.782 3.68 3.088 3.207 3.446 8.08 1.007 13.72-3.51 8.117-13.537 17.966-22.013 17.966z"
      ></path>
      <path
        fill="#5551FF"
        d="M96.943 71.057L94.68 82.69s-15.434 1.926-31-2.999c-7.267-2.299-13.993-5.913-19.325-11.217-3.464-3.437-8.196-12.584-8.196-12.584L54.853 43.32s-2.845 15.733 14.56 21.798c17.398 6.065 27.53 5.938 27.53 5.938z"
      ></path>
      <path
        fill="#5551FF"
        d="M87.107 83.249c-15.287 0-31.972-3.774-42.892-14.635-3.446-3.42-8.185-12.542-8.232-12.634a.199.199 0 01.066-.256l18.693-12.568a.199.199 0 01.307.2c-.029.156-2.608 15.639 14.43 21.576 17.174 5.987 27.355 5.926 27.463 5.926a.2.2 0 01.197.237l-2.263 11.632a.199.199 0 01-.171.16c-.067.008-3.02.362-7.598.362zM36.418 55.954c.644 1.224 4.923 9.248 8.077 12.378 5.371 5.343 12.14 8.92 19.245 11.169 14.556 4.605 29.177 3.182 30.773 3.008l2.19-11.255c-1.649-.03-11.585-.45-27.356-5.947-15.715-5.477-15.04-18.949-14.749-21.575l-18.18 12.222z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M45.238 69.595c-1.435-1.326-2.719-2.623-5.286-6.698a.198.198 0 11.336-.212c2.566 4.071 3.858 5.36 5.22 6.618a.199.199 0 01-.27.292z"
      ></path>
      <path
        fill="#fff"
        d="M43.679 84.158s.37 15.942 33.204 21.822c20.338 3.641 28.685 29 31.225 39.083"
      ></path>
      <path
        fill="#1E1E1E"
        d="M108.108 145.262a.198.198 0 01-.193-.15c-2.647-10.508-10.972-35.338-31.067-38.936-17.017-3.047-25.178-8.849-29.028-13.18-4.197-4.72-4.336-8.667-4.34-8.833a.199.199 0 01.194-.203.2.2 0 01.203.194c.002.04.149 3.993 4.266 8.609 3.813 4.272 11.899 10 28.775 13.022 20.322 3.639 28.716 28.647 31.383 39.23a.2.2 0 01-.193.247z"
      ></path>
      <path
        fill="#fff"
        d="M41.18 32.506l-7.266 45.396a4.93 4.93 0 01-4.49 4.135l-6.73.518c-3.273.252-5.873-2.706-5.203-5.92l7.136-34.243a14.256 14.256 0 0110.255-10.859l4.019-1.08a1.827 1.827 0 012.279 2.053z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M17.296 76.594l7.136-34.243a14.384 14.384 0 0110.398-11.01l4.02-1.08a2.027 2.027 0 012.526 2.276L34.11 77.933a5.113 5.113 0 01-4.67 4.303l-6.73.518c-3.41.26-6.111-2.815-5.414-6.16zm21.657-45.95l-4.02 1.081a13.988 13.988 0 00-10.111 10.707l-7.137 34.243c-.644 3.09 1.856 5.925 4.995 5.682l6.73-.518a4.716 4.716 0 004.308-3.969l7.266-45.396a1.63 1.63 0 00-2.031-1.83z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M41.179 32.508l-7.266 45.395a4.907 4.907 0 01-4.14 4.09 5.162 5.162 0 00.958-2.286L38.6 30.533l.299-.078a1.824 1.824 0 012.28 2.053z"
      ></path>
      <path
        fill="#1E1E1E"
        d="M29.614 81.874a4.94 4.94 0 00.92-2.198l7.87-49.175a.2.2 0 01.146-.16l.299-.078a2.023 2.023 0 012.526 2.277L34.11 77.934a5.086 5.086 0 01-4.307 4.255.199.199 0 01-.188-.315zm9.162-51.181l-7.849 49.045a5.33 5.33 0 01-.72 1.968 4.692 4.692 0 003.51-3.835l7.266-45.394a1.625 1.625 0 00-2.032-1.83l-.175.046zM102.87 160.237a.198.198 0 01.164-.228l20.685-3.41a.2.2 0 01.229.163.2.2 0 01-.164.229l-20.686 3.41a.198.198 0 01-.228-.164zM90.332 79.89a.199.199 0 01-.161-.23l1.594-9.018a.196.196 0 01.23-.16c.108.018.18.121.162.23l-1.595 9.018a.199.199 0 01-.23.16zM39.472 204.325a.199.199 0 01-.001-.281l3.422-3.433a.199.199 0 01.282.28l-3.422 3.433a.199.199 0 01-.281.001zM48.405 195.08l10.078-10.11a.199.199 0 01.282.28l-10.078 10.111c-.092.092-.207.07-.277.002-.073-.07-.098-.189-.005-.283zM32.102 206.158a.199.199 0 010-.281l15.51-15.561a.199.199 0 01.282.28l-15.51 15.561a.2.2 0 01-.282.001zM7.427 181.602a.199.199 0 010-.281l3.421-3.433a.199.199 0 01.282.28l-3.422 3.433a.199.199 0 01-.281.001zM16.361 172.638a.199.199 0 010-.281l10.078-10.111a.2.2 0 01.282.281l-10.079 10.111a.2.2 0 01-.28 0zM.058 183.434a.199.199 0 010-.281l15.51-15.561a.199.199 0 01.282.281L.34 183.434a.2.2 0 01-.282 0zM119.309 145.262H95.113a.199.199 0 010-.397h24.196a.198.198 0 010 .397z"
      ></path>
    </svg>
  );
}

export default AvatarIcon;
