import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g fill="#D80000" clipPath="url(#clip0_24_3628)">
        <path d="M9.393 6.75a.75.75 0 00.75-.75V3.75a2.25 2.25 0 012.25-2.25h1.857a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-1.857a2.25 2.25 0 01-2.25-2.25V12a.75.75 0 10-1.5 0v2.25a3.754 3.754 0 003.75 3.75h1.857A3.754 3.754 0 0018 14.25V3.75A3.755 3.755 0 0014.25 0h-1.857a3.755 3.755 0 00-3.75 3.75V6a.75.75 0 00.75.75z"></path>
        <path d="M.85 10.59l3.44 3.44a.75.75 0 101.06-1.06L2.153 9.773 13.5 9.75a.75.75 0 100-1.5l-11.391.023L5.351 5.03a.75.75 0 10-1.06-1.06L.85 7.41a2.25 2.25 0 00-.001 3.18z"></path>
      </g>
      <defs>
        <clipPath id="clip0_24_3628">
          <path
            fill="#fff"
            d="M0 0H18V18H0z"
            transform="rotate(-180 9 9)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoutIcon;
