import React from "react";
import { AvatarIcon, SearchIcon, AddIcon } from "../../assets/Icons";

const SearchSection = ({ open, setOpen, searchInput, setSearchInput }) => {
  return (
    <div className="flex items-center">
      {/* left side */}
      <div className="flex items-start sm:gap-10 gap-3 w-full sm:flex-row flex-col">
        <div className="flex items-center justify-center">
          <AvatarIcon />
        </div>
        <div className="flex flex-col py-8 w-full">
        
          <h1 className="text-[#1E1E1E] sm:text-4xl text-2xl font-dup-sans font-semibold capitalize mb-3 mt-2">
            Search for a session
          </h1>
          <div className="flex items-start flex-col md:flex-row md:items-center md:justify-between gap-4">
            {/* Search box */}
            <div className="search-bg flex items-center justify-between py-2 px-3 border border-transparent focus-within:border-themeColor anim w-full md:w-[410px]">
              <input
                type="text"
                placeholder="Search for Study Sessions..."
                className="w-full bg-transparent"
                value={searchInput} // Bind searchInput state
                onChange={(e) => setSearchInput(e.target.value)} 
              />
              <div className="flex items-center justify-center h-4 w-4">
                <SearchIcon />
              </div>
            </div>
            <button
              className="flex items-center btn bg-themeColor gap-2 py-[10px]"
              onClick={(e) => setOpen(true)}
            >
              <div className="flex items-center justify-center h-3 w-3">
                <AddIcon />
              </div>
              <label className="text-white text-sm font-semibold capitalize ">
                Create Session
              </label>
            </button>
          </div>
        </div>
      </div>
      {/* right side */}
    </div>
  );
};

export default SearchSection;
