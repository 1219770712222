import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authService = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => {
    return {
      userLogin: builder.mutation({
        query: (loginData) => {
          return {
            url: "api/users/login",
            method: "POST",
            body: loginData,
          };
        },
      }),
      userRegister: builder.mutation({
        query: (data) => {
          return {
            url: "api/users/register",
            method: "POST",
            body: data,
          };
        },
      }),
      userVerification: builder.mutation({
        query: (data) => {
          return {
            url: "api/users/verifyuser",
            method: "POST",
            body: data,
          };
        },
      }),
      userOTPCode: builder.mutation({
        query: (data) => {
          return {
            url: "api/users/sendotpemail",
            method: "POST",
            body: data,
          };
        },
      }),
      userResetPasswordEmail: builder.mutation({
        query: (data) => {
          return {
            url: "api/users/sendresetpasswordemail",
            method: "POST",
            body: data,
          };
        },
      }),
      userResetPassword: builder.mutation({
        query: (data) => {
          return {
            url: "api/users/resetpassword",
            method: "POST",
            body: data,
          };
        },
      }),
      
    };
  },
});
export const { useUserResetPasswordEmailMutation,useUserResetPasswordMutation,useUserRegisterMutation, useUserLoginMutation, useUserVerificationMutation,useUserOTPCodeMutation } = authService;
export default authService;
