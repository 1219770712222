import React from "react";
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  YouTubeIcon
} from "../../assets/Icons";

const Footer = () => {
  return (
    <div className="footer-comp flex bottom-0 left-0 right-0 w-full min-h-[60px] py-2 bg-[#F6F6FF]">
      <div className="wrapWidth flex flex-col">
        <div className="grid gap-10 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 pt-24">
          <div className="flex flex-col gap-3">
            <h1 className="c-666 text-lg font-normal leading-4 mb-2">Company</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Academic Integrity</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Honor Shield</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Institute of Digital Learning</h1>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="c-666 text-lg font-normal leading-4 mb-2">Company</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Our Story</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">News & media</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Locations</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Careers</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Blog</h1>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="c-666 text-lg font-normal leading-4 mb-2">Company</h1>
            <div className="flex items-center gap-1">
              <a href="/" className="flex items-center justify-center h-4 w-4">
                <FacebookIcon />
              </a>
              <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Facebook</h1>
            </div>
            <div className="flex items-center gap-1">
              <a href="/" className="flex items-center justify-center h-4 w-4">
                <TwitterIcon />
              </a>
              <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Twitter</h1>
            </div>
            <div className="flex items-center gap-1">
              <a href="/" className="flex items-center justify-center h-4 w-4">
                <InstagramIcon />
              </a>
              <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Instagram</h1>
            </div>
            <div className="flex items-center gap-1">
              <a href="/" className="flex items-center justify-center h-4 w-4">
                <YouTubeIcon />
              </a>
              <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Youtube</h1>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="c-666 text-lg font-normal leading-4 mb-2">Services</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Group Session</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Student Council</h1>
            <h1 className="text-[#1E1E1E] text-base font-normal leading-4 cursor-pointer  hover:text-themeColor anim">Student Advising</h1>
          </div>
        </div>
        <div className="w-full border-b border-solid border-[#D7D7D7] my-24"></div>
        <div className="flex items-center justify-between gap-5 py-5 sm:flex-row flex-col">
          <h1 className="text-[#1E1E1E] text-sm font-normal">© 2023 STUDYBUD.CA | All Rights Reserved</h1>
          <div className="flex items-center gap-4">
            <a href="/" className="text-[#1E1E1E] text-sm font-normal">Privacy</a>
            <div className="flex border-r border-solid border-[#1E1E1E] h-3"></div>
            <a href="/" className="text-[#1E1E1E] text-sm font-normal">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
