import React from "react";

function ClockIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 16 16"
        >
            <g fill="#6C6C6C" clipPath="url(#clip0_24_3854)">
                <path d="M10.85 9.413L8.62 7.74V4.332a.62.62 0 10-1.24 0V8.05c0 .195.092.379.248.495l2.478 1.86a.616.616 0 00.868-.125.619.619 0 00-.124-.867z"></path>
                <path d="M8 0C3.589 0 0 3.589 0 8c0 4.412 3.589 8 8 8 4.412 0 8-3.588 8-8 0-4.411-3.588-8-8-8zm0 14.76A6.769 6.769 0 011.24 8 6.769 6.769 0 018 1.24 6.769 6.769 0 0114.76 8 6.77 6.77 0 018 14.76z"></path>
            </g>
            <defs>
                <clipPath id="clip0_24_3854">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default ClockIcon;