import packageJson from "../../package.json";

export const thousandAndDecimalFormat = (data, allowDecimal, decimalScale) => {
  let decimalPoint = allowDecimal ? (decimalScale ? decimalScale : 0) : 0;
  return (isNaN(data)) ? Number.parseFloat(0).toFixed(decimalPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : Number.parseFloat(data).toFixed(decimalPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getVersion = () => {
  return packageJson?.version ? packageJson.version : "1.0.0";
}


// Formate like January 12, 2024
export const formatDate = (inputDate) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateObject = new Date(inputDate);
  return dateObject.toLocaleDateString('en-US', options).toUpperCase();
}

export const formatChatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Format the hours to be in 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Pad minutes with leading zero if needed
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
