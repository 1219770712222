// Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
//import { Link } from 'react-router-dom';


const Headertemp = () => {
  return (
    <AppBar position="static" color="default" elevation={0} sx={{ background: 'transparent', boxShadow: 'none' }}>
      <Toolbar sx={{ justifyContent: 'space-between', paddingLeft: '0 !important', paddingRight: '0 !important' }}>
        {/* Logo Section */}
        <Typography 
  variant="h6" 
  color="inherit" 
  noWrap 
  sx={{ 
    flexGrow: 1,
    fontWeight: 'bold', // Makes the text bold
    fontSize: '1.5rem' // Increases the font size, adjust the value as needed
  }}
>
  <span style={{ color: '#5551FF' }}>Study</span>
  <span style={{ color: '#00633E' }}>bud</span>
</Typography>



        {/* Middle Navigation Links */}
       

        {/* Right Side Buttons */}
      {/*  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>

        <Link to="https://studybud.ca/auth/login" style={{ textDecoration: 'none' }}>
        <Button
            variant="contained"
            startIcon={<RocketLaunchIcon />}
            sx={{
              my: 1,
              mx: 1.5,
              backgroundColor: '#5551FF',
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(85,81,255, 0.7)'
              }
            }}
          >
            Log In
          </Button>
          </Link>
       

          <Link to="https://studybud.ca/auth/register" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            startIcon={<RocketLaunchIcon />}
            sx={{
              my: 1,
              mx: 1.5,
              backgroundColor: '#5551FF',
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(85,81,255, 0.7)'
              }
            }}
          >
            Sign up
          </Button>
          </Link>
        </Box>*/}
      </Toolbar>
    </AppBar>
  );
};

export default Headertemp;

