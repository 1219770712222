import React, { useState, useEffect } from 'react'
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import { CalenderIcon, ClockIcon, RocketSolidIcon } from '../../assets/Icons'
import {
    useGetSingleSessionQuery,
    useCreateNewSessionMutation,
    useUpdateSessionMutation
} from "../../store/services/sessionService";

const CreateSession = ({ setOpen, selectedSessionId, setSelectedSessionId }) => {
    function convertTimeStringToDate(timeString) {
        const [hours, minutes] = timeString.split(':');
        const now = new Date();
        now.setHours(Number(hours), Number(minutes), 0, 0);

        return now;
    }
    function subtractOneDay(dateString) {
        const originalDate = new Date(dateString);
        originalDate.setDate(originalDate.getDate() - 1);
        // Return the new date with the same time component
        return originalDate.toISOString();
    }

    const {
        data: singleSessionData,
        isLoading: singleSessionDataLoading,
        isFetching: singleSessionDataFetching,
    } = useGetSingleSessionQuery(selectedSessionId);


    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState('');

    const [state, setState] = useState({
        title: "",
        description: "",
        date: selectedDate,
        time: selectedTime,
        location: "",
        subject: "",
        participantLimit: "",
    });

    const handleInputs = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const [sessionData, response] = useCreateNewSessionMutation();
    const [updateSessionData, updateSessionResp] = useUpdateSessionMutation();

    const createSessionFunction = (e) => {
        e.preventDefault();
        if (state.time === "") {
            toast.error("Please select time!")
        } else {
            if (state?._id) {
                updateSessionData(state)
            } else {
                sessionData(state);
            }
        }
    };

    useEffect(() => {
        if (response.isSuccess) {
            toast.success("Session created successfully!");
            setOpen(false)
        } else if (response.isError) {
            toast.error(response?.error?.data?.message || "Some thing wrong!")
        }
    }, [response]);

    useEffect(() => {
        if (singleSessionData) {
            setSelectedTime(convertTimeStringToDate(singleSessionData.time))
            setState(singleSessionData)
        }
    }, [singleSessionData])

    useEffect(() => {
        if (updateSessionResp.isSuccess) {
            toast.success("Session updated successfully!");
            setOpen(false)
        } else if (updateSessionResp.isError) {
            setOpen(false)
            toast.error(updateSessionResp?.error?.data?.message || "Some thing wrong!")
        }
    }, [updateSessionResp]);


    return (
        <div className="flex flex-col sm:w-[480px] w-[320px] min-h-[200px] h-full bg-white sm:p-8 p-4">
            <h1 className='text-[#1E1E1E] font-dup-sans sm:text-4xl text-xl font-semibold capitalize mb-1'>{state?._id ? "Edit a session" : "Create a new session"}</h1>
            <p className='text-[#6C6C6C] text-sm font-normal leading-5'>Create a custom session so others can join!</p>
            <form
                onSubmit={createSessionFunction}
                className='flex flex-col my-5 gap-5'>
                <div className='flex flex-col'>
                    <label className='text-[#1E1E1E] font-medium text-sm mb-1'>Session Title*</label>
                    <input
                        name='title'
                        type='text'
                        required
                        placeholder='example - Foundation 101'
                        value={state.title}
                        onChange={handleInputs}
                        className='search-bg text-[#828084] font-normal text-sm p-3 anim border border-transparent border-solid  focus:border-themeColor'
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='text-[#1E1E1E] font-medium text-sm mb-1'>Description*</label>
                    <textarea
                        name='description'
                        type='text'
                        required placeholder='Description...'
                        value={state.description}
                        onChange={handleInputs}
                        className='search-bg text-[#828084] font-normal text-sm p-3 anim border border-transparent border-solid  focus:border-themeColor h-28 max-h-28'
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='text-[#1E1E1E] font-medium text-sm mb-1'>Date & Time*</label>
                    <div className='flex items-center gap-3 sm:flex-row flex-col'>
                        <div className='flex items-center gap-2 search-bg text-[#828084] font-normal text-sm p-3 anim w-full'>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => {
                                    setSelectedDate(date);
                                    setState({ ...state, date: date })
                                }}
                            />
                            <div className='flex items-center justify-center h-3 w-3'>
                                <CalenderIcon />
                            </div>
                        </div>
                        <div className='flex items-center gap-2 search-bg text-[#828084] font-normal text-sm p-3 anim w-full'>
                            <DatePicker
                                selected={selectedTime}
                                onChange={(date) => {
                                    setSelectedTime(date);
                                    setState({ ...state, time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) });
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                            <div className='flex items-center justify-center h-3 w-3'>
                                <ClockIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <label className='text-[#1E1E1E] font-medium text-sm mb-1'>Location*</label>
                    <input
                        name='location'
                        type='text'
                        required placeholder='eg; Library, Dorm, Online'
                        value={state.location}
                        onChange={handleInputs}
                        className='search-bg text-[#828084] font-normal text-sm p-3 anim border border-transparent border-solid  focus:border-themeColor'
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='text-[#1E1E1E] font-medium text-sm mb-1'>Subject*</label>
                    <input
                        name='subject'
                        type='text'
                        required placeholder='eg; English'
                        value={state.subject}
                        onChange={handleInputs}
                        className='search-bg text-[#828084] font-normal text-sm p-3 anim border border-transparent border-solid  focus:border-themeColor'
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='text-[#1E1E1E] font-medium text-sm mb-1'>Participant Limit*</label>
                    <input
                        name='participantLimit'
                        type='number' // Changed from text to number
                        required
                        min='1' // Minimum number set to 1
                        max='15' // Optional: You can set a maximum value if needed
                        placeholder='eg; 12'
                        value={state.participantLimit}
                        onChange={handleInputs}
                        className='search-bg text-[#828084] font-normal text-sm p-3 anim border border-transparent border-solid  focus:border-themeColor'
                    />
                </div>

                <div className='flex items-center gap-3 sm:flex-row flex-col'>
                    <button type="submit" className="flex items-center justify-center btn bg-themeColor gap-2 py-[10px] w-full"
                    >
                        <div className="flex items-center justify-center h-3 w-3">
                            <RocketSolidIcon />
                        </div>
                        <label className="text-white text-sm font-semibold capitalize ">
                            {state?._id ? "Save Changes" : " Start a Session"}
                        </label>
                    </button>
                    <button className="flex items-center justify-center btn bg-[#CACACA] gap-2 py-[10px] w-full" onClick={(e) => setOpen(false)}>
                        <label className="text-[#6C6C6C] text-sm font-semibold capitalize ">
                            Close
                        </label>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateSession