import React from "react";

function CalenderIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 18 18"
        >
            <g clipPath="url(#clip0_24_4892)">
                <path
                    fill="#828084"
                    d="M15.68 1.406h-1.617V.563a.562.562 0 10-1.126 0v.843H5.064V.563a.562.562 0 10-1.125 0v.843H2.32C1.04 1.406 0 2.447 0 3.726V15.68C0 16.96 1.04 18 2.32 18h13.36c1.28 0 2.32-1.04 2.32-2.32V3.727c0-1.28-1.04-2.32-2.32-2.32zM2.32 2.531h1.618v.563a.563.563 0 001.124 0V2.53h7.875v.563a.562.562 0 101.126 0V2.53h1.617c.659 0 1.195.536 1.195 1.196v1.336H1.125V3.726c0-.66.536-1.196 1.195-1.196zm13.36 14.344H2.32c-.659 0-1.195-.536-1.195-1.195V6.188h15.75v9.492c0 .659-.536 1.195-1.195 1.195z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_24_4892">
                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CalenderIcon;