import React from "react";

function InstagramIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#6C6C6C"
                fillRule="evenodd"
                d="M2.098 3.763C1.5 4.805 1.5 6.203 1.5 9s0 4.195.6 5.237a4.508 4.508 0 001.663 1.664c1.042.6 2.44.6 5.237.6s4.195 0 5.237-.6a4.508 4.508 0 001.664-1.664c.6-1.042.6-2.44.6-5.237s0-4.195-.6-5.237a4.508 4.508 0 00-1.664-1.664C13.194 1.5 11.796 1.5 9 1.5s-4.195 0-5.237.6a4.508 4.508 0 00-1.664 1.663zm10.045 11.192c-.748.068-1.719.07-3.144.07s-2.396-.002-3.144-.07c-.727-.065-1.099-.184-1.359-.334a3.033 3.033 0 01-1.119-1.119c-.15-.26-.268-.631-.334-1.359-.067-.747-.069-1.719-.069-3.144s.002-2.396.07-3.144c.065-.727.184-1.098.333-1.359a3.032 3.032 0 011.12-1.119c.26-.15.63-.268 1.358-.333.748-.068 1.72-.07 3.144-.07 1.425 0 2.396.002 3.144.07.728.065 1.099.184 1.358.333.466.268.852.654 1.12 1.12.15.26.269.63.334 1.358.067.748.069 1.72.069 3.144 0 1.425-.002 2.397-.07 3.144-.064.728-.184 1.1-.333 1.359a3.034 3.034 0 01-1.119 1.12c-.26.149-.631.268-1.359.333zM11.9 5.09a1.008 1.008 0 112.015 0 1.008 1.008 0 01-2.014 0zm-2.9 0a3.91 3.91 0 100 7.821 3.91 3.91 0 000-7.821zM6.663 9a2.336 2.336 0 114.67 0 2.336 2.336 0 01-4.67 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default InstagramIcon;