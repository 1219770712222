import React from "react";

function DotMenuIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="4"
            fill="none"
            viewBox="0 0 19 4"
        >
            <rect width="5" height="4" fill="#D9D9D9" rx="2"></rect>
            <rect width="5" height="4" x="7" fill="#D9D9D9" rx="2"></rect>
            <rect width="5" height="4" x="14" fill="#D9D9D9" rx="2"></rect>
        </svg>
    );
}

export default DotMenuIcon;