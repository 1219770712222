
import Headertemp from '../../components/LandingTemp/Headertemp';
import HeroSectionTemp from '../../components/LandingTemp/HeroSectionTemp';
import FeatureSection from '../../components/LandingTemp/FeatureSection';
import { Box, useTheme, ThemeProvider } from '@mui/material';
import StatisticsSectionTemp from '../../components/LandingTemp/StatisticsSectionTemp';
//import AboutFounderSection from './Landing/AboutFounderSection';
import CallToActionSectionTemp from '../../components/LandingTemp/CallToActionSectionTemp';


const LandingPageTemp = () => {
    const theme = useTheme();
    return (
        <ThemeProvider theme={theme}>
      
      <Box
        sx={{
          marginX: '10%', // sets horizontal margin to 10% of the viewport width
          [theme.breakpoints.down('md')]: {
            marginX: '5%', // adjusts horizontal margins to 5% on medium and smaller screens
          },
          [theme.breakpoints.down('sm')]: {
            marginX: '2%', // further adjusts horizontal margins to 2% on small screens
          },
        }}
      >
        <Headertemp />
        <HeroSectionTemp />
        <FeatureSection />
        <StatisticsSectionTemp />
        
      </Box>
      {/* AboutFounderSection is outside the Box that sets the margins */}
      
     
      <CallToActionSectionTemp />
     
      
      

      {/* ... other sections of your page ... */}
    </ThemeProvider>
    
  );
}

export default LandingPageTemp;