import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";

import authService from "./services/authService";
import userService from "./services/userService"
import sessionService from "./services/sessionService"
import chatService from "./services/chatService"


const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [userService.reducerPath]: userService.reducer,
    [sessionService.reducerPath]: sessionService.reducer,
    [chatService.reducerPath]: chatService.reducer,
    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      [
        authService.middleware,
        userService.middleware,
        sessionService.middleware,
        chatService.middleware,
      ]
    ),
});

setupListeners(store.dispatch);

export default store;
