import React from "react";
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Public from "./Public"
import Private from "./Private"
import Login from "../Pages/Login"
import Registration from "../Pages/Registration"
import ForgetPassword from '../Pages/ForgetPassword'
import ResetPassword from '../Pages/ResetPassword'
import Home from "../Pages/Home";
import Settings from "../Pages/settings";
import Chat from "../Pages/Chat";
import LandingPageTemp from "../Pages/LandingPage/LandingPageTemp";


const Routing = () => {
  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <LandingPageTemp />
            </Public>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <Public>
              <ForgetPassword />
            </Public>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <Public>
              <ResetPassword />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          />
        </Route>
        <Route path="dashboard">
          <Route
            path="home"
            element={
              <Private>
                <Home />
              </Private>
            }
          />
          <Route
            path="settings"
            element={
              <Private>
                <Settings />
              </Private>
            }
          />
          <Route
            path="chat/:sessionId"
            element={
              <Private>
                <Chat />
              </Private>
            }
          />
        </Route>

      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
