import React from "react";

function ArrowLeftIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 16 15"
        >
            <path
                stroke="#1E1E1E"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7.5H1m0 0L7.5 14M1 7.5L7.5 1"
            ></path>
        </svg>
    );
}

export default ArrowLeftIcon;