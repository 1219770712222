import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import './settings.css'
// import { useUserUpdateSettingMutation, } from '../../store/services/authService';
import Wrapper from "../../routes/Wrapper";
import {
    useGetProfileQuery,
    useUserUpdateSettingMutation
  } from "../../store/services/userService";

const Main = () => {
  const [open, setOpen] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [sendresetreq, response] = useUserUpdateSettingMutation();
  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const {
    data: userProfileData,
    isLoading: userProfileDataLoading,
    isFetching: userProfileDataFetching,
  } = useGetProfileQuery();
  console.log("userProfileData",userProfileData)
  useEffect(() => {
    if(userProfileData) {
        if(userProfileData?.settings?.disablemessagesnotification) {
           setToggle1(userProfileData?.settings?.disablemessagesnotification)
        }
        if(userProfileData?.settings?.disablesessionnotification) {
            setToggle2(userProfileData?.settings?.disablesessionnotification)
        }
    }
  },[userProfileData])

  const toggleButton1 = async () => {
    setToggle1(!toggle1);
    await sendresetreq({disablemessagesnotification : !toggle1})
  };

  const toggleButton2 = async () => {
    setToggle2(!toggle2);
    await sendresetreq({disablesessionnotification : !toggle2})
  };


  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
    } else if (response.isError) {
      toast.error(response?.error?.data?.message || "Some thing wrong!")
    }
  }, [response])


  return (
    <Wrapper>
      <div className="lading-page bg-white min-h-[730px] h-full w-full py-10">
      <div className="toggle-container">
          <div className="toggle-switch">
            <p> Disable Messages notifications</p>
            <div className={`slider ${toggle1 ? 'active' : ''}`} onClick={toggleButton1}></div>
          </div>
          <div className="toggle-switch">
            <p>Disable Session notifications</p>
            <div className={`slider ${toggle2 ? 'active' : ''}`} onClick={toggleButton2}></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Main;
