import React from "react";

function FacebookIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#6C6C6C"
                d="M16.5 9.045a7.5 7.5 0 10-8.672 7.41v-5.242H5.923V9.044h1.905V7.393c0-1.88 1.119-2.918 2.832-2.918.82 0 1.679.146 1.679.146v1.846h-.945c-.932 0-1.223.578-1.223 1.172v1.407h2.08l-.332 2.167H10.17v5.241A7.502 7.502 0 0016.5 9.045z"
            ></path>
        </svg>
    );
}

export default FacebookIcon;