import React from "react";

function ProfileIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#fff"
                d="M8 7.962a2.493 2.493 0 110-4.987 2.493 2.493 0 010 4.987zm0-4.294a1.8 1.8 0 100 3.601 1.8 1.8 0 000-3.601zM12.329 13.025a.346.346 0 01-.347-.347v-.856a2.483 2.483 0 00-2.48-2.48H6.497a2.483 2.483 0 00-2.48 2.48v.857a.346.346 0 01-.693 0v-.857A3.177 3.177 0 016.497 8.65h3.005a3.177 3.177 0 013.173 3.173v.857a.346.346 0 01-.347.346z"
            ></path>
        </svg>
    );
}

export default ProfileIcon;