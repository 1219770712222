import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate,useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useUserResetPasswordMutation,useUserResetPasswordEmailMutation } from '../../store/services/authService';
import { setUserToken } from '../../store/reducers/authReducer';
import { Container, CssBaseline, TextField, Button, Checkbox, FormControlLabel, Typography, Box, Link } from '@mui/material';

function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token")
  const [sendresetreq, response] = useUserResetPasswordMutation();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!credentials.password || !credentials.confirmpassword) {
      toast.error("Please input password!");
      return;
    }
    if (credentials.password.length < 8) {
      toast.error("Password must be at least 8 characters long!");
      return;
    }
    if (credentials.password !== credentials.confirmpassword) {
      toast.error("Passwords do not match!");
      return;
    }
    await sendresetreq({...credentials,token})
  };

  const handleChange = (event) => {
    console.log("event",event.target.name)
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      setTimeout(() => {
        navigate(`/auth/login`);
      }, 1000);
    } else if (response.isError) {
      toast.error(response?.error?.data?.message || "Something went wrong!");
    }
  }, [response, navigate]);



  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%', // Set width to 100% of the container
        }}
      >
        <Typography  sx={{ mb: 4, color: 'primary.main' }}>
        Reset Password
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={credentials.password}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm Password"
            type="password"
            id="confirmpassword"
            autoComplete="current-password"
            value={credentials.confirmpassword}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: 'primary.main' }}
          >
            {response.isLoading ? "Loading..." : "Reset Password"}
          </Button>
         
        </Box>
      </Box>
    </Container>
  );
}

export default LogIn;
