import React from "react";

function SendIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 16 16"
        >
            <g clipPath="url(#clip0_24_4229)">
                <path
                    stroke="#fff"
                    d="M3.666 8.666L12 4m-1.167 11.666H11a48.46 48.46 0 014.336-14.64l.17-.36-.173-.173-.359.17A48.46 48.46 0 01.333 5v.167L3.666 8.5v5.166h.167l1.183-1.126c.466-.444.983-.83 1.542-1.149l4.275 4.275z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_24_4229">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SendIcon;