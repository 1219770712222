import React from "react";

function ChatIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 16 16"
        >
            <g clipPath="url(#clip0_24_3882)">
                <path
                    fill="#fff"
                    d="M8 0C3.589 0 0 3.589 0 8v7.5a.5.5 0 00.5.5H8c4.411 0 8-3.589 8-8s-3.589-8-8-8zm.5 10h-4a.5.5 0 010-1h4a.5.5 0 010 1zm3-3h-7a.5.5 0 010-1h7a.5.5 0 010 1z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_24_3882">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default ChatIcon;