import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import FactGraphicSVG from '../../assets/Icons/factGraphic.svg' ; // Replace with your SVG path
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Link } from 'react-router-dom';

const StatisticsSectionTemp = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <img src={FactGraphicSVG} alt="Study Graphic" style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" gutterBottom>
            PROVEN FACT
          </Typography>
          <Typography variant="h3" gutterBottom>
            82% Of Students Get <span style={{ color: '#5551FF' }}>Higher Grades</span> When Studying With Others
          </Typography>
          <Typography variant="body1" paragraph>
          Welcome to our exciting space for collective study! 
          Engage in a lively community built to enhance your learning experience. 
          Effortlessly set up or participate in study sessions suited to your courses, 
          meet peers who share your academic goals, and tap into the shared knowledge and energy that group study provides.
          </Typography>
          <Link to="https://studybud.ca/auth/register" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            startIcon={<RocketLaunchIcon />}
            sx={{
              my: 1,
              mx: 1.5,
              backgroundColor: '#00633E',
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(85,81,255, 0.7)'
              }
            }}
          >
            Get Started
          </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsSectionTemp;
