import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from "../utils/common";
import { BellIcon, ChatIcon, ClockIcon, DotMenuIcon, LocationIcon, ProfileIcon } from '../assets/Icons'

const BrowseSessionsCard = ({
    key,
    item,
    setOpen,
    joinSessionFunction,
    deleteSessionFunction,
    setSelectedSessionId }) => {
    const [show, setShow] = useState(false);

    document.addEventListener("click", () => {
        document.body.style.overflow = "inherit";
        setShow(false);
    });

    return (
        <div key={key} className='flex flex-col w-full p-8 gap-4 rounded-lg border border-[#D7D7D7] border-sold'>
            <div className='flex sm:flex-row flex-col-reverse justify-between sm:gap-14 gap-2'>
                <h1 className='flex flex-1 text-[#1E1E1E] font-semibold text-xl font-dup-sans'>{item.title}</h1>
                <div className='flex flex-[0.6] justify-end items-start relative'>
                   {/* <div className='flex items-center justify-center w-14 cursor-pointer p-1'
                        onClick={(e) => {
                            e.stopPropagation();
                            setShow(!show);
                        }}
                    >
                        <DotMenuIcon />
                    </div>
                    {/* Sub Menu for list */}
                    <div className={`subMenu flex col ${show ? "show" : "hide"}`}>
                        <div className="text-black font-normal text-sm py-[6px] px-5 cursor-pointer font hover:bg-yellow-100"
                            onClick={(e) => {
                                setOpen(true);
                                setSelectedSessionId(item._id)
                            }}
                        >
                            Edit Session
                        </div>
                        <div className="text-black font-normal text-sm py-[6px] px-5 cursor-pointer font hover:bg-yellow-100"
                            onClick={(e) => deleteSessionFunction(item._id)}
                        >
                            Delete Session
                        </div>
                    </div>
                </div>
            </div> 
            <div className='flex justify-between sm:gap-8 gap-4 sm:flex-row flex-col'>
                <div className='flex flex-1 items-center gap-2 flex-wrap'>
                    <button className={`btn py-1 px-3 uppercase text-sm text-[#1E1E1E] font-normal ${item.subject === "physics" ? "bg-[#A2A29E]" : "bg-[#FEF182]"}`}>
                        {item.subject}
                    </button>
                    <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase bg-[#00633E] text-sm font-normal'>
                        <div className='flex items-center justify-center h-4 w-4'>
                            <ProfileIcon />
                        </div>
                        <h6 className='text-[#FFF] text-sm font-normal'>{`${item.participantCount}/${item.participantLimit}`}</h6>
                    </button>
                    <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase border border-[#D7D7D7] border-solid text-sm font-normal'>
                        <div className='flex items-center justify-center h-4 w-4'>
                            <ClockIcon />
                        </div>
                        <h6 className='text-[#6C6C6C] text-sm font-normal'>{formatDate(item.date)} {" "} {item.time}</h6>
                    </button>
                    <button className='flex items-center justify-between gap-2 btn py-1 px-3 uppercase border border-[#D7D7D7] border-solid text-sm font-normal'>
                        <div className='flex items-center justify-center h-4 w-4'>
                            <LocationIcon />
                        </div>
                        <h6 className='text-[#6C6C6C] text-sm font-normal'>{item.location}</h6>
                    </button>
                    <button className='flex items-center justify-between gap-2 btn py-1 px-3 border border-[#D7D7D7] border-solid text-sm font-normal rounded-full' style={{textTransform: 'none'}}>
    <h6 className='text-[#6C6C6C] text-sm font-normal'>{item.description}</h6>
</button>

                </div>
                <div className='flex flex-[0.5] flex-col items-end justify-end gap-4'>
                    
                    <button className='flex items-center justify-between gap-2 btn py-2 px-3 uppercase bg-[#5551FF] text-sm font-normal'
                        onClick={(e) => joinSessionFunction(item._id)}
                    >
                        {/* <div className='flex items-center justify-center h-4 w-4'>
                            <ChatIcon />
                        </div> */}
                        <h6 className='text-white text-sm font-normal'>Join Session</h6>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BrowseSessionsCard