import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import { LogoutIcon } from "../../assets/Icons";
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import {
  useGetProfileQuery,
} from "../../store/services/userService";

const Header = ({ openSidebar, setOpenSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: userProfileData,
    isLoading: userProfileDataLoading,
    isFetching: userProfileDataFetching,
  } = useGetProfileQuery();

  // console.log("userProfileData...", userProfileData)

  const userLogout = () => {
    dispatch(logout("userKey"));
    navigate("/");
  };
  return (
    <div className="header-camp flex z-50 top-0 left-0 right-0 w-full min-h-[70px] py-3 px-0 bg-white bd-bottom">
      <div className="wrapWidth flex items-center sm:gap-5 gap-2">
        {/* Left Side */}
        <div className="flex sm:flex-1 flex-[0.3] items-center">
          <div className="flex items-center justify-center">
            <Link to="/">
            <Typography 
  variant="h6" 
  color="inherit" 
  noWrap 
  sx={{ 
    flexGrow: 1,
    fontWeight: 'bold', // Makes the text bold
    fontSize: '1.5rem' // Increases the font size, adjust the value as needed
  }}
>
  <span style={{ color: '#5551FF' }}>Study</span>
  <span style={{ color: '#00633E' }}>bud</span>
</Typography>
            </Link>
          </div>
        </div>
        {/* Right Side */}
        <div className="flex flex-1 items-center justify-end sm:gap-5 gap-2">
          {!userProfileDataLoading ?
            <div className="flex items-center gap-2">
               <div 
               style={{cursor : 'pointer'}}
               onClick={() => {
                navigate("/dashboard/settings");
               }}
               className="h-8 w-8 rounded-full bg-gray-300 text-gray-600 font-semibold flex items-center justify-center">
                {userProfileData?.name.substring(0, 1).toUpperCase()}
              </div>
              <div className="flex flex-col">
                <h1 className="text-lg font-medium font-dup-sans leading-5 text-[#1E1E1E]">
                  {userProfileData?.name || "-"}
                </h1>
                <label className="text-[#6C6C6C] text-xs font-medium">
                  {userProfileData?.email || "-"}
                </label>
              </div>
            </div>
            :
            null
          }
          <button className="flex items-center gap-2 py-2 px-4 rounded-md" onClick={userLogout}>
            <div className="flex items-center justify-center h-4 w-4">
              <LogoutIcon />
            </div>
            <label className="text-[#6C6C6C] text-sm font-medium">
              Log Out
            </label>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
