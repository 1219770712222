import React from "react";

function NotificationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fill="#5551FF"
                d="M19.793 8.334a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
            ></path>
            <path
                fill="#5551FF"
                d="M20.626 9.813c-.021 0-.042.01-.063.01-.104.02-.208.031-.323.052-.437.042-.906.02-1.385-.073-.125-.031-.23-.052-.344-.094a3.579 3.579 0 01-.969-.396c-.125-.062-.25-.145-.364-.218-.5-.344-.927-.771-1.271-1.271-.073-.115-.156-.24-.219-.365a3.58 3.58 0 01-.396-.968c-.041-.115-.062-.22-.093-.344a4.84 4.84 0 01-.073-1.386c.02-.114.03-.218.052-.322 0-.021.01-.042.01-.063a1.041 1.041 0 00-1.02-1.25H7.833c-.146 0-.292.01-.427.02-.125.011-.24.022-.365.042a3.84 3.84 0 00-.364.053c-2.51.364-4.115 1.958-4.48 4.479-.02.114-.041.24-.052.364-.02.125-.03.24-.041.365-.01.135-.021.281-.021.427v8.292c0 .145.01.291.02.427.011.125.022.24.042.364.01.125.032.25.053.365.364 2.52 1.968 4.114 4.479 4.48.114.02.24.04.364.051.125.021.24.031.365.042.135.01.281.02.427.02h8.292c.145 0 .291-.01.427-.02.125-.01.24-.021.364-.042.125-.01.25-.031.365-.052 2.51-.364 4.114-1.958 4.48-4.48.02-.114.04-.239.051-.364.021-.125.031-.24.042-.364.01-.136.02-.282.02-.427v-6.334c0-.666-.604-1.146-1.25-1.02zM7.032 13.02h5.208c.427 0 .781.354.781.781a.787.787 0 01-.78.781H7.03a.787.787 0 01-.78-.78c0-.428.354-.782.78-.782zm9.375 5.729H7.032a.787.787 0 01-.781-.781c0-.427.354-.782.78-.782h9.376c.427 0 .781.355.781.782a.787.787 0 01-.781.781z"
            ></path>
        </svg>
    );
}

export default NotificationIcon;