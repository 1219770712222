import React from "react";

function LocationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#6C6C6C"
                d="M11.812 2.033A6.04 6.04 0 006.796.784a6 6 0 00-2.447 10.638 10.166 10.166 0 012.614 2.811l.466.776a.666.666 0 001.143 0l.447-.745a8.481 8.481 0 012.386-2.666 5.992 5.992 0 00.408-9.568l-.001.003zM8 9.333A2.667 2.667 0 118 4a2.667 2.667 0 010 5.334z"
            ></path>
        </svg>
    );
}

export default LocationIcon;