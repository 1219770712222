import React from "react";

function BellIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="#6C6C6C"
                d="M3.152 8.908a.323.323 0 00.323-.323 7.476 7.476 0 012.207-5.32.323.323 0 00-.457-.456A8.117 8.117 0 002.83 8.585a.323.323 0 00.323.323zM18.528 8.585a.323.323 0 00.646 0 8.115 8.115 0 00-2.415-5.795.323.323 0 00-.455.458 7.473 7.473 0 012.224 5.337z"
            ></path>
            <path
                fill="#6C6C6C"
                d="M18.85 17.42a1.423 1.423 0 01-1.42-1.422V8.585C17.077.06 4.925.056 4.572 8.585v7.413a1.423 1.423 0 01-1.421 1.421 1.195 1.195 0 000 2.39H18.85a1.195 1.195 0 000-2.39zm0 1.744H3.152a.55.55 0 110-1.099 2.07 2.07 0 002.067-2.067V8.585c.316-7.668 11.246-7.672 11.564 0v7.413a2.07 2.07 0 002.067 2.067.55.55 0 010 1.099z"
            ></path>
            <path
                fill="#6C6C6C"
                d="M14.272 11.532h-2.948V8.585a.323.323 0 00-.646 0v2.947H7.731a.323.323 0 000 .646h2.947v2.948a.323.323 0 00.646 0v-2.948h2.948a.323.323 0 000-.646z"
            ></path>
        </svg>
    );
}

export default BellIcon;