import React from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as HeroGraphic } from '../../assets/Icons/Group.svg';


const HeroSectionTemp = () => {

  const theme = useTheme();
  return (
    <Box   sx={{
      paddingTop: '5%', // responsive padding on the top
      paddingBottom: '5%', // responsive padding on the bottom
      [theme.breakpoints.down('md')]: {
        paddingTop: '3%', // less padding on medium screens
        paddingBottom: '3%',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '2%', // even less padding on small screens
        paddingBottom: '2%',
      },
    }}
  >

<Box>
  <a href="https://www.studybud.ca/auth/register" target="_blank" rel="noopener noreferrer">
    <Button
      variant="contained"
      sx={{
        my: 1,
        mx: 1.5,
        padding: '12px 30px', // Increase padding for a larger button
        fontSize: '1.25rem', // Increase font size for larger text
        minWidth: '200px', // Set a minimum width
        height: '50px',
        backgroundColor: '#5551FF',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(85,81,255, 0.7)'
        }
      }}
    >
      REGISTER NOW
    </Button>
  </a>
  <a href="https://www.studybud.ca/auth/login" target="_blank" rel="noopener noreferrer">
    <Button
      variant="contained"
      sx={{
        my: 1,
        mx: 1.5,
        padding: '12px 30px', // Increase padding for a larger button
        fontSize: '1.25rem', // Increase font size for larger text
        minWidth: '200px', // Set a minimum width
        height: '50px',
        backgroundColor: '#5551FF',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(85,81,255, 0.7)'
        }
      }}
    >
      LOG IN
    </Button>
  </a>
</Box>

      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="overline" display="block" gutterBottom>
            ACADEMIC EXCELLENCE
          </Typography>
          <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
      Connect. <span style={{ color: '#5551FF' }}>Collaborate.</span> Conquer.
    </Typography>
          <Typography variant="subtitle1" gutterBottom>
          Join us and shift from solitary study to a collaborative, campus-focused journey, tailored specifically for university students to find study groups and connect with peers right where you are.<br></br>
          
          Follow us on our socials to stay up to date!
    
          </Typography>
          <Box>
  <a href="https://www.instagram.com/studybud.ca/" target="_blank" rel="noopener noreferrer">
    <Button
      variant="contained"
      sx={{
        my: 1,
        mx: 1.5,
        backgroundColor: '#5551FF',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(85,81,255, 0.7)'
        }
      }}
    >
      Instagram
    </Button>
  </a>
  <a href="https://www.linkedin.com/company/studybudofficial/" target="_blank" rel="noopener noreferrer">
    <Button
      variant="contained"
      sx={{
        my: 1,
        mx: 1.5,
        backgroundColor: '#5551FF',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(85,81,255, 0.7)'
        }
      }}
    >
      LinkedIn
    </Button>
  </a>
</Box>

        </Grid>
        <Grid item xs={12} md={6}>
          <HeroGraphic style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSectionTemp;
