import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useUserLoginMutation,useUserResetPasswordEmailMutation } from '../../store/services/authService';
import { setUserToken } from '../../store/reducers/authReducer';
import { Container, CssBaseline, TextField, Button, Checkbox, FormControlLabel, Typography, Box, Link } from '@mui/material';

function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendresetreq, response] = useUserResetPasswordEmailMutation();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    await sendresetreq({email : credentials?.email})
  };

  const handleChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
    } else if (response.isError) {
      toast.error(response?.error?.data?.message || "Something wrong!");
      if(response?.error?.data?.message == "Please verify your email to login") {
        navigate(`/auth/register?email=${credentials?.email}`);
      }
    }
  }, [response, dispatch, navigate]);

  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%', // Set width to 100% of the container
        }}
      >
        <Typography  sx={{ mb: 4, color: 'primary.main' }}>
         Enter the email address associated with your account and we'll send you a link to reset your password.
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username/Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={credentials.email}
            onChange={handleChange}
          />
          {/* <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={credentials.password}
            onChange={handleChange}
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: 'primary.main' }}
          >
            {response.isLoading ? "Loading..." : "Continue"}
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="body2">
            Dont have an account?
              <Link href="#" variant="body2">
                Signup?
              </Link>
            </Typography>
            {/* <Typography variant="body2">
              <Link href="/auth/register" variant="body2">
                New Register
              </Link>
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default LogIn;
