import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Wrapper from "../../routes/Wrapper";
import SearchSection from "./SearchSection";
import CreateSession from "./CreateSession"
import MySessionsCard from "../../components/MySessionsCard";
import BrowseSessionsCard from "../../components/BrowseSessionsCard";
import { FilterIcon, ReloadIcon } from "../../assets/Icons"
import {
  useGetBrowseSessionQuery,
  useGetMySessionQuery,
  useJoinSessionMutation,
  useLeaveSessionMutation,
  useDeleteSessionMutation
} from "../../store/services/sessionService"

const Main = () => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("mySessions");
  const [selectedSessionId, setSelectedSessionId] = useState("")
  const [searchInput, setSearchInput] = useState('');

  


  const {
    data: browseSessionsData,
    isLoading: browseSessionsDataLoading,
    isFetching: browseSessionsDataFetching,
  } = useGetBrowseSessionQuery();

  const {
    data: mySessionsData,
    isLoading: mySessionsDataLoading,
    isFetching: mySessionsDataFetching,
  } = useGetMySessionQuery();

  const filteredBrowseSessions = browseSessionsData?.filter((session) => {
    // Preprocess the search input to remove spaces and convert to lower case
    const processedSearchInput = searchInput.replace(/\s+/g, '').toLowerCase();
  
    // Check if any of the properties match the processed search input
    const matchesTitle = session.title.replace(/\s+/g, '').toLowerCase().includes(processedSearchInput);
    const matchesDescription = session.description.replace(/\s+/g, '').toLowerCase().includes(processedSearchInput);
    const matchesLocation = session.location.replace(/\s+/g, '').toLowerCase().includes(processedSearchInput);
    const matchesSubject = session.subject.replace(/\s+/g, '').toLowerCase().includes(processedSearchInput);
  
    return matchesTitle || matchesDescription || matchesLocation || matchesSubject;
  });
  

const filteredmySessions = mySessionsData?.filter((session) =>
session.title.toLowerCase().includes(searchInput.toLowerCase()) ||
session.description.toLowerCase().includes(searchInput.toLowerCase()) ||
session.subject.toLowerCase().includes(searchInput.toLowerCase()) ||
session.location.toLowerCase().includes(searchInput.toLowerCase())
);

  
  // console.log("mySessionsData...", mySessionsData)
  // console.log("browseSessionsData...", browseSessionsData)

  const [joinSession, joinResp] = useJoinSessionMutation();
  const joinSessionFunction = (sessionId) => {
    // e.preventDefault();
    joinSession({ sessionId });
  };
  useEffect(() => {
    if (joinResp.isSuccess) {
      toast.success(joinResp?.data?.message);
      window.location.reload();
    } else if (joinResp.isError) {
      toast.error(joinResp?.error?.data?.message || "Some thing wrong!")
    }
  }, [joinResp])

  const [leaveSession, leaveSessionResp] = useLeaveSessionMutation();
  const leaveSessionFunction = (sessionId) => {
    leaveSession({ sessionId });
  };
  useEffect(() => {
    if (leaveSessionResp.isSuccess) {
      toast.success(leaveSessionResp?.data?.message);
      window.location.reload();
    } else if (leaveSessionResp.isError) {
      toast.error(leaveSessionResp?.error?.data?.message || "Some thing wrong!")
    }
  }, [leaveSessionResp])


  const [deleteSession, deleteSessionResp] = useDeleteSessionMutation();
  const deleteSessionFunction = (sessionId) => {
    deleteSession({ sessionId });
  };

  useEffect(() => {
    if (deleteSessionResp.isSuccess) {
      toast.success(deleteSessionResp?.data?.message);
      window.location.reload();
    } else if (deleteSessionResp.isError) {
      toast.error(deleteSessionResp?.error?.data?.message || "Some thing wrong!")
    }
  }, [deleteSessionResp])


  return (
    <Wrapper>
      <div className="lading-page bg-white min-h-[730px] h-full w-full py-10">
        <div className="wrap wrapWidth flex flex-col gap-20">
        <SearchSection open={open} setOpen={setOpen} searchInput={searchInput} setSearchInput={setSearchInput} />

          <div className="flex flex-col w-full">
            {/* Filter Tabs */}
            <div className="flex items-end justify-between sm:gap-5 gap-1">
              <div className="flex items-center sm:gap-3 gap-1">
                <div className={`flex items-center h-16 sm:w-64 w-max px-4 font-dup-sans text-black border-t border-l border-r border-[#D7D7D7] cursor-pointer rounded-t-lg anim ${activeTab === "mySessions" ? "btn-active" : ""}`}
                  onClick={(e) => setActiveTab("mySessions")}>
                  <h1 className="sm:text-lg text-sm font-bold">My sessions</h1>
                </div>
                <div className={`flex items-center h-16 sm:w-64 w-max px-4 font-dup-sans text-black border-t border-l border-r border-[#D7D7D7] cursor-pointer rounded-t-lg anim ${activeTab === "browseSessions" ? "btn-active" : ""}`}
                  onClick={(e) => setActiveTab("browseSessions")}>
                  <h1 className="sm:text-lg text-sm font-bold ">Browse Sessions</h1>
                </div>
              </div>
            {/*  <button className="btn anim flex items-center gap-2 border border-[#D7D7D7] border-solid py-1 px-3">
                <div className="flex items-center justify-center h-3 w-3">
                  <FilterIcon />
                </div>
                <label className="text-[#1E1E1E] text-lg font-normal capitalize">Filter</label>
  </button> */}
            </div>

            {
              activeTab === "mySessions" ?
                <>
                  {
                    mySessionsData?.length > 0 ?
                      <div className="grid gap-6 md:grid-cols-2 grid-cols-1 py-6">
                        {filteredmySessions.map((item, index) => (
                          <MySessionsCard
                            key={index}
                            item={item}
                            setOpen={setOpen}
                            leaveSessionFunction={leaveSessionFunction}
                            deleteSessionFunction={deleteSessionFunction}
                            setSelectedSessionId={setSelectedSessionId}
                          />

                        ))}
                      </div>
                      :
                      <div className="min-h-[280px] flex items-center justify-center">
                        <h1 className="sm:text-lg text-sm font-bold">Go join a session you silly goose</h1>
                      </div>
                  }
                </>
                :
                <>
                  {
                    browseSessionsData?.length > 0 ?
                      <div className="grid gap-6 md:grid-cols-2 grid-cols-1 py-6">
                        {filteredBrowseSessions.map((item, index) => (
                          <BrowseSessionsCard
                            key={index}
                            item={item}
                            setOpen={setOpen}
                            joinSessionFunction={joinSessionFunction}
                            deleteSessionFunction={deleteSessionFunction}
                            setSelectedSessionId={setSelectedSessionId}
                          />
                        ))}
                      </div>
                      :
                      <div>
                        <h1 className="">No Sessions Currently</h1>
                      </div>
                  }
                </>
            }
           
          </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <CreateSession
            setOpen={setOpen}
            selectedSessionId={selectedSessionId}
            setSelectedSessionId={setSelectedSessionId}
          />
        </Modal>
      </div>
    </Wrapper>
  );
};

export default Main;
