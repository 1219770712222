import React from "react";

function RocketSolidIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 17 16"
        >
            <g clipPath="url(#clip0_24_4926)">
                <path
                    fill="#fff"
                    d="M5.394 12.028l-.966-.966a.977.977 0 01-.24-1.006c.093-.278.218-.64.368-1.056H1.25a.753.753 0 01-.653-.378.746.746 0 01.006-.754l1.64-2.765A2.25 2.25 0 014.179 4H6.75c.075-.125.15-.24.225-.353 2.56-3.775 6.372-3.9 8.647-3.482.362.066.644.35.712.713.42 2.278.291 6.087-3.48 8.647-.11.075-.229.15-.354.225v2.572c0 .793-.419 1.53-1.103 1.934l-2.766 1.64A.75.75 0 017.5 15.25V11.9c-.44.154-.825.279-1.116.373a.985.985 0 01-.993-.244h.003zM12.5 5.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_24_4926">
                    <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default RocketSolidIcon;