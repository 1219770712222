import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate,useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useUserRegisterMutation,useUserVerificationMutation,useUserOTPCodeMutation } from '../../store/services/authService';
import { setUserToken } from '../../store/reducers/authReducer';
import { Container, CssBaseline, TextField, Button, Typography, Box, Link, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


function Register() {

  const schools = [
    { label: 'University of Toronto', id: 1 },
    { label: 'McMaster University', id: 2 },
    { label: 'University of Waterloo', id: 3 },
    { label: 'Western University', id: 4 },
    { label: 'University of Ottawa', id: 5 },
    { label: 'Queens University', id: 6 },
    { label: 'Carleton University', id: 7 },
    { label: 'Ryerson University', id: 8 },
    { label: 'York University', id: 9 },
    { label: 'University of Windsor', id: 10 },
    { label: 'Wilfrid Laurier University', id: 11 },
    { label: 'University of Guelph', id: 12 },
    { label: 'Ontario Tech University', id: 13 },
    { label: 'Brock University', id: 14 },
    { label: 'Lakehead University', id: 15 },
    { label: 'Laurentian University', id: 16 },
    { label: 'Nipissing University', id: 17 },
    { label: 'Algoma University', id: 18 },
    { label: 'Trent University', id: 19 },
    { label: 'OCAD University', id: 20 },
    { label: 'Royal Military College of Canada', id: 21 }
];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [register, response] = useUserRegisterMutation();
  const [userotpcode, otpresponse] = useUserOTPCodeMutation();
  const [verification, verificationresponse] = useUserVerificationMutation();
  const [verificationEmail,setVerificationEmail] = useState("")
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    schoolName : null
  });
  const [enableVerification, setenableVerification] = useState(false);
  const [verificationCode, setverificationCode] = useState("");

  const handleChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (credentials.password !== credentials.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    if (credentials.password.length < 8) {
      toast.error("Password must be at least 8 characters long!");
      return;
    }
    const schoolName = credentials.schoolName ? credentials.schoolName.label : '';
    await register({
      name: credentials.name,
      email: credentials.email,
      password: credentials.password,
      schoolName: schoolName
    });
  };
  console.log("verificationCode",verificationCode)

  const handleVerifySubmit = async (event) => {
    event.preventDefault();
    console.log("handleVerifySubmit")
    await verification({
      email: verificationEmail,
      verificationCode: verificationCode
    });
  };

  const handleSchoolChange = (event, newValue) => {
    setCredentials({
      ...credentials,
      schoolName: newValue,
    });
  };

  useEffect(() => {
    let email = searchParams.get("email")
    console.log("email".email)
    if(email) {
      setenableVerification(true)
      //setCredentials({...credentials,email : email})
      setVerificationEmail(email)
      userotpcode({email : verificationEmail })
    }
  },[])

  useEffect(() => {
    if (verificationresponse.isSuccess) {
      toast.success(response?.data?.message);
      setenableVerification(true)
      navigate("/auth/login");
    } else if (verificationresponse.isError) {
      console.log('error')
      toast.error(verificationresponse?.error?.data?.message || "Something went wrong!");
    }
  }, [verificationresponse, navigate]);  

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      setenableVerification(true)
      setVerificationEmail(credentials?.email)
      setCredentials({name: "",
      email: "",
      password: "",
      confirmPassword: "",
      schoolName : null})
      //navigate("/auth/login");
    } else if (response.isError) {
      toast.error(response?.error?.data?.message || "Something went wrong!");
    }
  }, [response, navigate]);

  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 4, color: 'primary.main' }}>
          {enableVerification ? "OTP Verification" : "Register"}
        </Typography>
        {
          enableVerification ?
          <Box component="form" noValidate onSubmit={handleVerifySubmit} sx={{ mt: 1 }}>
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              We've sent a verification code to your email -  {verificationEmail}
            </Alert>
          <TextField
            margin="normal"
            required
            fullWidth
            id="verificationCode"
            label="Enter Verification Code"
            name="verificationCode"
            //autoComplete="verification"
            autoFocus
            value={verificationCode}
            onChange={(event) => {
              setverificationCode(event.target.value);
            }}
          />
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: 'primary.main' }}
          >
            {response.isLoading ? "Loading..." : "Verify Email"}
          </Button>
        </Box>
        
        :
        
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={credentials.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={credentials.email}
            onChange={handleChange}
          />
       <Box sx={{ mt: 4 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={schools}
          getOptionLabel={(option) => option.label} // Specifies how to extract the label from each option
          value={credentials.schoolName}
          onChange={handleSchoolChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select your school"
              required
            />
          )}
          fullWidth
        />
      </Box>

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={credentials.password}
            onChange={handleChange}
          />
           <Typography variant="caption" display="block" gutterBottom color="textSecondary" sx={{ mb: 2 }}>
  Password must be at least 8 characters long.
</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={credentials.confirmPassword}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: 'primary.main' }}
          >
            {response.isLoading ? "Loading..." : "Register"}
          </Button>
          <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
            <Typography variant="body2">
              <Link href="/auth/login" variant="body2">
                Already registered? Sign in
              </Link>
            </Typography>
          </Box>
        </Box>
        }
      </Box>
    </Container>
  );
}

export default Register;
