import React from 'react';
import { Box, Typography } from '@mui/material';
import CombinedSVG from '../../assets/Icons/Group 6.svg';

const FeatureSection = () => {
  return (
    <Box
      sx={{
        paddingY: '5%', // Vertical padding for top and bottom
        '& img': {
          width: '100%', // SVG takes the full width of its container
          maxWidth: '1000px', // Sets the maximum width the SVG can scale up to
          height: 'auto', // Keeps the aspect ratio of the SVG
        }
      }}
    >
      <Typography variant="subtitle1" align="center" gutterBottom>
        WHY CHOOSE US
      </Typography>
      <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4 }}>
        Elevate Your Learning Experience
      </Typography>
      <Box sx={{ width: '100%', maxWidth: '1000px', margin: 'auto' }}>
        <img src={CombinedSVG} alt="Feature Graphic" />
      </Box>
    </Box>
  );
};

export default FeatureSection;


