import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const sessionService = createApi({
    reducerPath: "sessionService",
    tagTypes: "session",

    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,

        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const authorization = reducers.authReducer?.userToken;
            headers.set(
                "Authorization",
                `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
            );
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => {
        return {
            //   Create new Session
            createNewSession: builder.mutation({
                query: (formData) => {
                    return {
                        url: "/api/study-sessions/create",
                        method: "POST",
                        body: formData,
                    };
                },
                invalidatesTags: ["session"],
            }),

            //   Update my Session
            updateSession: builder.mutation({
                query: (formData) => {
                    return {
                        url: `/api/study-sessions/${formData._id}/edit`,
                        method: "PUT",
                        body: formData,
                    };
                },
                invalidatesTags: ["session"],
            }),

            //   Join Session
            joinSession: builder.mutation({
                query: ({ sessionId }) => {
                    return {
                        url: `/api/study-sessions/${sessionId}/join`,
                        method: "POST",
                    };
                },
                invalidatesTags: ["session"],
            }),

            //   Leave Session
            leaveSession: builder.mutation({
                query: ({ sessionId }) => {
                    return {
                        url: `/api/study-sessions/${sessionId}/leave`,
                        method: "POST",
                    };
                },
                invalidatesTags: ["session"],
            }),

            //   Delete a Session
            deleteSession: builder.mutation({
                query: ({ sessionId }) => {
                    return {
                        url: `/api/study-sessions/${sessionId}/delete`,
                        method: "DELETE",
                    };
                },
                invalidatesTags: ["session"],
            }),

            //   Get Browse Sessions list
            getBrowseSession: builder.query({
                query: () => {
                    return {
                        url: "/api/study-sessions/allSessions",
                        method: "GET",
                    };
                },
                providesTags: ["session"],
            }),

            //   Get My Session list
            getMySession: builder.query({
                query: () => {
                    return {
                        url: "/api/study-sessions/mySessions",
                        method: "GET",
                    };
                },
                providesTags: ["session"],
            }),

            //   Get Single Session by id
            getSingleSession: builder.query({
                query: (sessionId) => {
                    return {
                        url: `/api/study-sessions/session/${sessionId}`,
                        method: "GET",
                    };
                },
                providesTags: ["session"],
            }),

        };
    },
});

export const {
    useGetBrowseSessionQuery,
    useGetMySessionQuery,
    useGetSingleSessionQuery,
    useCreateNewSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation,
    useJoinSessionMutation,
    useLeaveSessionMutation,
} = sessionService;

export default sessionService;