import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const chatService = createApi({
    reducerPath: "chatService",
    tagTypes: "chat",

    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,

        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const authorization = reducers.authReducer?.userToken;
            headers.set(
                "Authorization",
                `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
            );
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => {
        return {

            // Send new Message
            sendNewMessage: builder.mutation({
                query: ({ formData, chatId }) => {
                    return {
                        url: `/api/chats/${chatId}/sendMessage`,
                        method: "POST",
                        body: formData,
                    };
                },
                invalidatesTags: ["chat"],
            }),

            //   Get Chat Id
            getChatId: builder.query({
                query: (sessionId) => {
                    return {
                        url: `/api/chats/session/${sessionId}/chat`,
                        method: "GET",
                    };
                },
                providesTags: ["chat"],
            }),

            //   Get Messages list
            getMessagesList1: builder.query({
                query: (chatId) => {
                    return {
                        url: `/api/chats/${chatId}/messages`,
                        method: "GET",
                    };
                },
                providesTags: ["chat"],
            }),

            //   Get Messages list
            getMessagesList: builder.mutation({
                query: (chatId) => {
                    return {
                        url: `/api/chats/${chatId}/messages`,
                        method: "GET",
                    };
                },
                invalidatesTags: ["chat"],
            }),
        };
    },
});

export const {
    useGetChatIdQuery,
    useGetBrowseSessionQuery,
    useSendNewMessageMutation,
    useGetMessagesListMutation,
} = chatService;

export default chatService;