import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Link } from 'react-router-dom';

const CallToActionSectionTemp = () => {
  return (
    <Box sx={{ bgcolor: '#00633E', color: 'white', py: 4, textAlign: 'center' }}>
      <Typography variant="overline" display="block" gutterBottom>
        READY TO STUDY TOGETHER?
      </Typography>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Enhance Your Learning Journey!
      </Typography>
      <Link to="https://studybud.ca/auth/register" style={{ textDecoration: 'none' }}>
      <Button startIcon={<RocketLaunchIcon />} variant="contained" color="secondary" sx={{ backgroundColor: '#fff', color: '#00633E', '&:hover': { backgroundColor: '#f7f7f7' } }}>
        Sign Up! 
      </Button>
      </Link>
    </Box>
  );
};

export default CallToActionSectionTemp;
