import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 19"
    >
      <g clipPath="url(#clip0_24_3814)">
        <path
          fill="#1E1E1E"
          d="M17.947 17.22l-4.477-4.477a7.512 7.512 0 10-1.06 1.06l4.476 4.477a.75.75 0 001.06-1.06zM7.667 14a6 6 0 116-6 6.007 6.007 0 01-6 6z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_24_3814">
          <path
            fill="#fff"
            d="M0 0H18V18H0z"
            transform="translate(.167 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SearchIcon;
