import React from "react";

function YouTubeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 18 18"
        >
            <g clipPath="url(#clip0_114_35)">
                <path
                    fill="#6C6C6C"
                    d="M17.748 4.712c-.094-.788-.837-1.544-1.614-1.645a57.848 57.848 0 00-14.269 0c-.778.101-1.52.857-1.615 1.645a36.776 36.776 0 000 8.578c.094.788.837 1.545 1.615 1.645 4.738.589 9.53.589 14.269 0 .777-.1 1.52-.857 1.614-1.645a36.773 36.773 0 000-8.578zM7.5 12.002v-6l4.5 3-4.5 3z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_114_35">
                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default YouTubeIcon;